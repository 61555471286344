<template>
  <div class="second-page">
    <div class="background-overlay"></div>
    <div class="content">
      <!-- 添加算命师傅图片 -->
      <div class="fortune-teller">
        <img src="@/assets/miaosense.jpg" alt="算命先生" class="fortune-teller-img">
      </div>
      
      <h1 class="title">卦象推算</h1>
      
      <!-- 加载状态显示 -->
      <div v-if="loading" class="loading">
        <div class="loading-spinner"></div>
        <p>正在推算卦象...</p>
      </div>

      <!-- 错误信息显示 -->
      <div v-else-if="error" class="error">
        <p>{{ error }}</p>
        <button @click="retryFetch" class="retry-button">重试</button>
      </div>

      <!-- 数据显示 -->
      <div v-else-if="data" class="hexagram-display">
        <div class="hexagram-item">
          <h2>日卦</h2>
          <p>{{ data.day_hexagram }}</p>
        </div>
        <div class="hexagram-item">
          <h2>时卦</h2>
          <p>{{ data.hour_hexagram }}</p>
        </div>
        <button @click="goToThirdPage" class="action-button">解签详解9.9元</button>
      </div>
    </div>
    <CustomerService />
  </div>
</template>

<script>
import CustomerService from '@/components/CustomerService.vue'

export default {
  name: 'SecondPage',
  components: {
    CustomerService
  },
  data() {
    return {
      data: null,
      loading: false,
      error: null,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.error = null
      
      try {
        const response = await fetch('https://michenkeji.top/api/get-hexagram', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        
        const data = await response.json()
        
        if (data.error) {
          throw new Error(data.error)
        }
        
        this.data = data
        console.log('Received data:', data) // 调试用
      } catch (error) {
        console.error('Error fetching data:', error)
        this.error = '卦象推算失败，请稍后重试'
      } finally {
        this.loading = false
      }
    },
    retryFetch() {
      this.fetchData()
    },
    goToThirdPage() {
      this.$router.push({ name: 'ResultPage' })
    }
  }
}
</script>

<style scoped>
.second-page {
  min-height: 100vh;
  position: relative;
  background-image: url('@/assets/bg-texture.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  color: #f1e6d0;
}

.title {
  font-size: clamp(1.8rem, 5vw, 2.5rem);
  margin-bottom: 2rem;
  font-family: "楷体", "STKaiti", serif;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.hexagram-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #d4af37;
}

.hexagram-item {
  text-align: center;
}

.hexagram-item h2 {
  font-family: "楷体", "STKaiti", serif;
  color: #d4af37;
  margin-bottom: 1rem;
}

.loading {
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f1e6d0;
  border-top: 4px solid #d4af37;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.error {
  color: #ff6b6b;
  text-align: center;
  padding: 1rem;
}

.action-button, .retry-button {
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  background: linear-gradient(45deg, #8b0000, #800000);
  color: #f1e6d0;
  border: 2px solid #d4af37;
  border-radius: 4px;
  font-family: "楷体", "STKaiti", serif;
  transition: all 0.3s ease;
}

.action-button:hover, .retry-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(212, 175, 55, 0.3);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }
  
  .hexagram-display {
    width: 90%;
    padding: 1rem;
  }
}

.fortune-teller {
  margin-bottom: 2rem;
  animation: floatAnimation 3s ease-in-out infinite;
}

.fortune-teller-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid #d4af37;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.5);
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* 添加呼吸光效果 */
.fortune-teller::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.2) 0%, rgba(212, 175, 55, 0) 70%);
  animation: breathe 4s ease-in-out infinite;
  z-index: -1;
}

@keyframes breathe {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}
</style>
  