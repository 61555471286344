// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'

// 假设 Home 和 SecondPage 组件现在放在 components 文件夹中
import SecondPage from '../view/SecondPage.vue'
import HomePage from '@/view/HomePage.vue'
import ResultPage from '@/view/ResultPage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/second',
    name: 'SecondPage',
    component: SecondPage,
  },
  {
    path: '/result',
    name: 'ResultPage',
    component: ResultPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
