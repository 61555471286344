<!-- src/view/Home.vue -->
<template>
  <div class="home">
    <div class="background-overlay"></div>
    <div class="content">
      <div class="logo-container">
        <img src="@/assets/bagua.png" alt="八卦图" class="rotating-bagua">
      </div>
      <h1 class="title">六壬寻物</h1>
      <p class="subtitle">明师指路 • 玄机预测</p>
      <button class="action-button" @click="goToSecondPage">
        <span class="button-text">寻物速断</span>
      </button>
    </div>
    <CustomerService />
  </div>
</template>

<script>
import CustomerService from '@/components/CustomerService.vue'

export default {
  name: 'HomePage',
  components: {
    CustomerService
  },
  methods: {
    goToSecondPage() {
      this.$router.push({ name: 'SecondPage' })
    },
  },
}
</script>

<style scoped>
.home {
  min-height: 100vh;
  position: relative;
  background-image: url('@/assets/bg-texture.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  color: #f1e6d0;
}

.logo-container {
  margin-bottom: 2rem;
}

.rotating-bagua {
  width: clamp(150px, 30vw, 250px);
  height: auto;
  animation: rotate 30s linear infinite;
}

.title {
  font-size: clamp(2rem, 6vw, 3.5rem);
  margin-bottom: 1rem;
  font-family: "楷体", "STKaiti", serif;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  letter-spacing: 0.5rem;
}

.subtitle {
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-bottom: 3rem;
  font-family: "楷体", "STKaiti", serif;
  opacity: 0.8;
  letter-spacing: 0.3rem;
}

.action-button {
  padding: clamp(0.8rem, 3vw, 1.2rem) clamp(2rem, 6vw, 3rem);
  font-size: clamp(1rem, 4vw, 1.2rem);
  cursor: pointer;
  background: linear-gradient(45deg, #8b0000, #800000);
  color: #f1e6d0;
  border: 2px solid #d4af37;
  border-radius: 4px;
  font-family: "楷体", "STKaiti", serif;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.action-button:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(212, 175, 55, 0.3);
}

.action-button:active {
  transform: translateY(1px);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shine {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 768px) {
  .title {
    letter-spacing: 0.3rem;
  }
  
  .subtitle {
    letter-spacing: 0.2rem;
  }
  
  .action-button {
    width: 80%;
  }
}
</style>
