<template>
  <div class="result-page">
    <div class="background-overlay"></div>
    <div class="scroll-container">
      <div class="scroll-content">
        <div class="scroll-header">
          <h1 class="title">六壬解签</h1>
        </div>
        
        <div class="divider">✧</div>
        
        <!-- 显示数据 -->
        <div v-if="result" class="interpretation-content">
          <div class="section">
            <h2 class="section-title">卦辞</h2>
            <div class="content-box">
              {{ result.hexagram }}
            </div>
          </div>
          
          <div class="divider">※</div>
          
          <div class="section">
            <h2 class="section-title">释义</h2>
            <div class="content-box">
              {{ result.interpretation }}
            </div>
          </div>
        </div>
        
        <!-- Loading状态 -->
        <div v-else class="loading">
          <div class="loading-spinner"></div>
          <p>正在推演...</p>
        </div>
        
        <div class="divider">✧</div>
        
        <!-- 返回按钮 -->
        <button @click="goBack" class="return-btn">收起卷轴</button>
      </div>
    </div>
    <CustomerService />
  </div>
</template>

<script>
import CustomerService from '@/components/CustomerService.vue'

export default {
  name: 'ResultPage',
  components: {
    CustomerService
  },
  data() {
    return {
      result: null,
    };
  },
  mounted() {
    this.fetchResult();
  },
  methods: {
    async fetchResult() {
      try {
        const response = await fetch('https://michenkeji.top/api/get-result');
        if (!response.ok) {
          throw new Error('Failed to fetch result');
        }
        this.result = await response.json();
      } catch (error) {
        console.error('Error fetching result:', error);
      }
    },
    goBack() {
      this.$router.push({ name: 'HomePage' });
    },
  },
};
</script>

<style scoped>
.result-page {
  min-height: 100vh;
  background-image: url('@/assets/bg-texture.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.scroll-container {
  position: relative;
  z-index: 2;
  width: 90%;
  max-width: 800px;
  background: rgba(249, 246, 241, 0.95);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  border: 2px solid #8B4513;
}

.scroll-content {
  position: relative;
  padding: 1rem;
}

.title {
  font-family: "楷体", "STKaiti", serif;
  color: #8B4513;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(139, 69, 19, 0.2);
}

.divider {
  text-align: center;
  color: #8B4513;
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.section {
  margin: 2rem 0;
}

.section-title {
  font-family: "楷体", "STKaiti", serif;
  color: #8B4513;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content-box {
  background: rgba(255, 253, 250, 0.8);
  border: 1px solid #D2B48C;
  padding: 1.5rem;
  border-radius: 4px;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #4A4A4A;
  text-align: center;
  font-family: "楷体", "STKaiti", serif;
}

.return-btn {
  display: block;
  margin: 2rem auto 0;
  padding: 0.8rem 2rem;
  background: #8B4513;
  color: #F9F6F1;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  font-family: "楷体", "STKaiti", serif;
  transition: all 0.3s ease;
}

.return-btn:hover {
  background: #A0522D;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(139, 69, 19, 0.3);
}

.loading {
  text-align: center;
  padding: 2rem;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #D2B48C;
  border-top: 4px solid #8B4513;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .scroll-container {
    width: 95%;
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .section-title {
    font-size: 1.5rem;
  }
  
  .content-box {
    font-size: 1.1rem;
    padding: 1rem;
  }
}
</style>
  