<template>
  <div class="customer-service">
    <!-- 客服图标 -->
    <div class="service-icon" @click="showQRCode">
      <img src="@/assets/service-icon.png" alt="联系客服" class="icon-img">
      <span class="icon-text">联系客服</span>
    </div>

    <!-- 二维码弹窗 -->
    <div v-if="isQRCodeVisible" class="qrcode-modal" @click.self="hideQRCode">
      <div class="qrcode-container">
        <div class="qrcode-header">
          <h3>扫码添加客服微信</h3>
          <span class="close-btn" @click="hideQRCode">&times;</span>
        </div>
        <div class="qrcode-content">
          <img src="@/assets/qrcode.jpg" alt="客服二维码" class="qrcode-img">
          <p>微信扫一扫，添加客服</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerService',
  data() {
    return {
      isQRCodeVisible: false
    }
  },
  methods: {
    showQRCode() {
      this.isQRCodeVisible = true
    },
    hideQRCode() {
      this.isQRCodeVisible = false
    }
  }
}
</script>

<style scoped>
.customer-service {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

.service-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: transparent;
  padding: 10px;
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease;
}

.service-icon:hover {
  transform: translateY(-2px);
  filter: drop-shadow(0 0 8px rgba(212, 175, 55, 0.5));
}

.icon-img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  filter: drop-shadow(0 0 3px rgba(212, 175, 55, 0.3));
}

.icon-text {
  font-size: 12px;
  color: #f1e6d0;
  font-family: "楷体", "STKaiti", serif;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.qrcode-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.qrcode-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
  width: 90%;
  position: relative;
}

.qrcode-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.qrcode-header h3 {
  margin: 0;
  color: #8B4513;
  font-family: "楷体", "STKaiti", serif;
}

.close-btn {
  cursor: pointer;
  font-size: 24px;
  color: #666;
  padding: 5px;
}

.qrcode-content {
  text-align: center;
}

.qrcode-img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.qrcode-content p {
  margin: 0;
  color: #666;
  font-family: "楷体", "STKaiti", serif;
}

@media (max-width: 768px) {
  .service-icon {
    padding: 8px;
  }

  .icon-img {
    width: 32px;
    height: 32px;
  }

  .icon-text {
    font-size: 10px;
  }
}
</style> 